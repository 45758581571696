import { useMutation, useQuery } from '@apollo/client';
import { useFilteredQuery, useSnackBars } from '@fingo/lib/hooks';
import useGetCountryFromUrl from
  '@fingo/lib/hooks/useGetCountryFromUrl';
import { CREATE_NON_OPERATIVE_MOVEMENT } from '../features/treasury/graphql/mutation/movement';
import { NON_OPERATIVE_MOVEMENTS, NON_OPERATIVE_MOVEMENTS_TYPES } from '../features/treasury/graphql/queries/movement';

const useCreateNonOperativeMovement = (
  {
    description,
    movementTypeId,
    active,
    passive,
    result,
    evidence,
    currency,
  },
  onCompletedCallback = () => {},
) => {
  const { deleteQueryFromCache } = useFilteredQuery(NON_OPERATIVE_MOVEMENTS, { skip: true });
  const { addAlert } = useSnackBars();
  const country = useGetCountryFromUrl();
  const mutation = useMutation(CREATE_NON_OPERATIVE_MOVEMENT, {
    variables: {
      movement: {
        description,
        movementTypeId,
        active: active || 0,
        passive: passive || 0,
        result: result || 0,
        evidence: evidence || null,
        currency: currency || null,
      },
      countryId: country?.id,
    },
    onCompleted: (data) => {
      deleteQueryFromCache();
      onCompletedCallback(data);
    },
    onError: (error) => {
      addAlert({
        id: error.name,
        message: error.message,
      });
    },
  });
  const movementTypeOptions = useQuery(NON_OPERATIVE_MOVEMENTS_TYPES);
  const movementOptions = movementTypeOptions.data?.nonOperativeMovementsTypes || [];
  return [mutation, movementOptions];
};

export default useCreateNonOperativeMovement;
