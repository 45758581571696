import { useMutation } from '@apollo/client';
import { useSnackBars } from '@fingo/lib/hooks';
import { CREDIT_LINE_EVALUATION_REQUESTS, RESOLVE_CREDIT_LINE_EVALUATION_REQUESTS } from '@fingo/lib/graphql';

const useResolveCreditLineEvaluationRequest = (
  {
    id,
    status,
    comment,
    approvedAmount = null,
    reEvaluationDate = null,
    onCompleted = () => {},
  },
) => {
  const { addAlert } = useSnackBars();
  const [resolveCreditLineEvaluationRequests, result] = useMutation(
    RESOLVE_CREDIT_LINE_EVALUATION_REQUESTS,
    {
      variables: {
        resolution: {
          id,
          status,
          comment,
          approvedAmount,
          reEvaluationDate,
        },
      },
      onCompleted: () => {
        addAlert({
          id: 'update-review-documents-manager',
          message: 'Se resolvieron las evaluaciones correspondientes',
        });
        onCompleted();
      },
      onError: () => {
        addAlert({
          id: 'update-review-documents-manager-error',
          message: 'Hubo un error, comunícate con el equipo de desarrollo',
        });
      },
      refetchQueries: [CREDIT_LINE_EVALUATION_REQUESTS],
    },
  );
  return [resolveCreditLineEvaluationRequests, result];
};

export default useResolveCreditLineEvaluationRequest;
