import { useMutation, useQuery } from '@apollo/client';
import { INVOICES_RATIFICATION } from '@fingo/lib/graphql';
import { useFilteredQuery, useSnackBars } from '@fingo/lib/hooks';
import { RESOLVE_RATIFICATION } from '../features/risk/graphql/ratification/mutations';

const acceptSelectOptions = [
  { value: 'mail', label: 'Factura ratificada por mail' },
  { value: 'phone', label: 'Factura ratificada por teléfono' },
  { value: 'receipt', label: 'Factura con acuse de recibo' },
  { value: 'merit', label: 'Factura con mérito ejecutivo' },
  { value: 'exception', label: 'Excepción: factura aprobada por el comité' },
];
const rejectSelectOptions = [
  { value: 'bad emission', label: 'Factura mal emitida' },
  { value: 'invoice payed', label: 'Servicios o productos pagados por el deudor' },
  { value: 'not requested', label: 'Servicios o productos no solicitados' },
  { value: 'partially delivered', label: 'Servicios o productos entregados parcialmente' },
  { value: 'not delivered', label: 'Ningún servicio o producto entregado' },
  { value: 'no authorization', label: 'No existe autorización por parte del deudor para factorizar' },
  { value: 'other', label: 'Otro' },
];

const useResolveRatification = (
  {
    invoiceIds,
    evidenceFile,
    evidenceContactId,
    resolutionType,
    observation,
    accepted,
  },
  onCompleted = () => {},
) => {
  const { addAlert } = useSnackBars();
  const { data } = useQuery(INVOICES_RATIFICATION, {
    variables: {
      id_In: invoiceIds,
    },
    skip: !invoiceIds.length,
  });
  const { deleteQueryFromCache } = useFilteredQuery(INVOICES_RATIFICATION, {
    skip: true,
  });
  const invoices = data?.invoices.edges.map((edge) => edge.node) || [];
  const ratificationManagerIds = invoices.map((inv) => inv.ratificationmanager.id);
  const resolveRatification = useMutation(RESOLVE_RATIFICATION, {
    variables: {
      ratificationManagerIds,
      accepted,
      evidenceFile,
      evidenceContactId,
      resolutionType,
      observation,
    },
    onError: (error) => addAlert(
      {
        id: error.name,
        message: error.message,
      },
    ),
    onCompleted: (returnedData) => {
      onCompleted(returnedData);
      deleteQueryFromCache();
    },
  });
  const selectOptions = accepted ? acceptSelectOptions : rejectSelectOptions;
  return [resolveRatification, selectOptions, invoices];
};

export default useResolveRatification;
