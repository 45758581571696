import { useQuery } from '@apollo/client';
import { MOVEMENT_ACCOUNTS } from '../features/treasury/graphql/queries/conciliation';

const useMovementAccountsOptions = () => {
  const { data, loading, ...extraQuery } = useQuery(MOVEMENT_ACCOUNTS);
  const movementAccounts = data?.conciliationMovementAccounts || [];
  const options = movementAccounts.map((account) => (
    {
      ...account,
      label: account.name,
    }
  ));
  return { data, loading, options, ...extraQuery };
};

export default useMovementAccountsOptions;
