import { useQuery } from '@apollo/client';
import { addMoneyWithCurrency } from '@fingo/lib/helpers';
import { CONCILIATION_MOVEMENTS } from '../features/treasury/graphql/queries/conciliation';

const useGetConciliationMovementByIds = (movementIds) => {
  const movements = useQuery(CONCILIATION_MOVEMENTS, {
    variables: {
      id_In: movementIds,
      first: 100,
    },
    skip: !movementIds.length,
  });
  const flatMovements = movements.data?.conciliationMovements.edges.map(
    (edge) => edge.node,
  ) || [];
  const debitValue = flatMovements.reduce(
    (previous, current) => addMoneyWithCurrency(
      previous,
      current.debit || 0,
    ),
    0,
  );
  const creditValue = flatMovements.reduce(
    (previous, current) => addMoneyWithCurrency(
      previous,
      current.credit || 0,
    ),
    0,
  );
  return { flatMovements, ...movements, debitValue, creditValue };
};

export default useGetConciliationMovementByIds;
