import CancelRounded from '@mui/icons-material/CancelRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpRounded from '@mui/icons-material/HelpRounded';
import WarningRounded from '@mui/icons-material/WarningRounded';
import React, { useCallback, useMemo } from 'react';
import { selectedRiskComponentVar } from '../features/risk/graphql/reactive-variables';

const usePredictiveModelsResults = (invoice) => {
  const { riskEvaluationModel, preoffer:
    { preofferevaluationrequest: { riskautomaticevaluationlogSet } } } = invoice;
  const riskautomaticevaluationlog = riskautomaticevaluationlogSet[0];

  const riskStatus = useMemo(() => {
    const results = riskEvaluationModel?.riskPredictionResults ?? [];
    if (!results.length) return 'warning';
    if (results.some((result) => result.isRejected)) return 'failure';
    if (results.some((result) => result.isAccepted)) return 'success';
    return 'unknown';
  }, [riskEvaluationModel]);

  const ratificationStatus = useMemo(() => {
    const withoutRatification = new Set([
      'WHITHOUT_RATIFICATION_CLIENT',
      'WHITHOUT_RATIFICATION_DEBTOR',
      'WITHOUT_RESTRICTION_CLIENT',
      'WITHOUT_RESTRICTION_DEBTOR',
    ]);
    const withRatification = new Set(['WITH_RATIFICATION']);
    const results = riskEvaluationModel?.ratificationResults ?? [];
    if (!results.length) return 'warning';
    if (results.some((result) => withoutRatification.has(result.ratificationResult))) return 'success';
    if (results.some((result) => withRatification.has(result.ratificationResult))) return 'failure';
    return 'unknown';
  }, [riskEvaluationModel]);

  const defaultStatus = useMemo(() => {
    const results = riskEvaluationModel?.riskDefaultResults ?? [];
    if (!results.length) return 'warning';
    if (results.some((result) => result.isRejected)) return 'failure';
    if (results.some((result) => result.isAccepted)) return 'success';
    return 'unknown';
  }, [riskEvaluationModel]);

  const clientLineStatus = useMemo(() => {
    const result = riskautomaticevaluationlog?.clientLineResult ? 'success' : 'failure';
    return result;
  }, [riskautomaticevaluationlog]);

  const debtorLineStatus = useMemo(() => {
    const result = riskautomaticevaluationlog?.debtorLineResult ? 'success' : 'failure';
    return result;
  }, [riskautomaticevaluationlog]);

  const iconStatuses = useMemo(() => ({
    success: <CheckCircleIcon color="success" />,
    failure: <CancelRounded color="error" />,
    warning: <WarningRounded color="warning" />,
    unknown: <HelpRounded color="warning" />,
  }), []);

  const handleRiskClick = useCallback(() => selectedRiskComponentVar('risk'), []);
  const handleRatificationClick = useCallback(() => selectedRiskComponentVar('ratification'), []);
  const handleDefaultClick = useCallback(() => selectedRiskComponentVar('default'), []);
  const handleClientLineClick = useCallback(() => selectedRiskComponentVar('clientLine'), []);
  const handleDebtorLineClick = useCallback(() => selectedRiskComponentVar('debtorLine'), []);

  const predictiveModelsConfig = useMemo(() => [
    {
      id: 'risk',
      title: 'Modelo de riesgo',
      icon: iconStatuses[riskStatus],
      onClick: handleRiskClick,
    },
    {
      id: 'ratification',
      title: 'Modelo de ratificación',
      icon: iconStatuses[ratificationStatus],
      onClick: handleRatificationClick,
    },
    {
      id: 'default',
      title: 'Modelo de default',
      icon: iconStatuses[defaultStatus],
      onClick: handleDefaultClick,

    },
    {
      id: 'clientLine',
      title: 'Línea por cliente',
      icon: iconStatuses[clientLineStatus],
      onClick: handleClientLineClick,
    },
    {
      id: 'debtorLine',
      title: 'Línea por deudor',
      icon: iconStatuses[debtorLineStatus],
      onClick: handleDebtorLineClick,
    },
  ], [riskStatus, ratificationStatus, defaultStatus, riskautomaticevaluationlog]);

  return { predictiveModelsConfig };
};

export default usePredictiveModelsResults;
