import { useMutation } from '@apollo/client';
import { formatYearMonthDay } from '@fingo/lib/helpers';
import { useFilteredQuery, useSnackBars } from '@fingo/lib/hooks';
import { useCallback } from 'react';
import { CONCILIATE_MOVEMENTS } from '../features/treasury/graphql/mutation/concilitation';
import { CONCILIATION_MOVEMENTS } from '../features/treasury/graphql/queries/conciliation';

const useConciliate = (
  debitMovements,
  creditMovements,
  selectedMovementType,
  conciliationDescription,
  onCompletedCallback = () => {},
) => {
  const { addAlert } = useSnackBars();
  const { deleteQueryFromCache } = useFilteredQuery(
    CONCILIATION_MOVEMENTS,
    { skip: true },
  );
  const movementMapper = useCallback((
    { movementId, movementDate, unpayedAmount, condonedAmount },
  ) => ({
    movementId,
    movementDate: movementDate && formatYearMonthDay(movementDate),
    unpayedAmount,
    condonedAmount,
  }), []);
  const conciliateMutation = useMutation(CONCILIATE_MOVEMENTS, {
    variables: {
      creditMovements: creditMovements.map(movementMapper),
      debitMovements: debitMovements.map(movementMapper),
      conciliationMovementTypeId: selectedMovementType,
      description: conciliationDescription,
    },
    onError: (error) => addAlert(
      {
        id: error.name,
        message: error.message,
      },
    ),
    onCompleted: (data) => {
      onCompletedCallback(data);
      deleteQueryFromCache();
    },
  });
  const inputOk = Boolean(selectedMovementType && conciliationDescription);
  return [...conciliateMutation, inputOk];
};

export default useConciliate;
