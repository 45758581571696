import { useMutation } from '@apollo/client';
import { CREATE_INVOICE_SIMULATION } from '@fingo/lib/graphql';

const useCreateInvoiceSimulation = (
  client,
  debtor,
  amount,
  days,
  setInvoices,
) => {
  const [createInvoice, { loading }] = useMutation(
    CREATE_INVOICE_SIMULATION,
    {
      variables: {
        invoice: {
          clientId: client?.id,
          debtorId: debtor?.id,
          daysToPay: days,
          amountWithIva: {
            amount,
            currency: 'CLP',
          },
        },
      },
      onCompleted: (data) => {
        setInvoices((prev) => [...prev, data.createSimulationInvoice.invoice]);
      },
      onError: () => {},
    },
  );
  return [createInvoice, loading];
};

export default useCreateInvoiceSimulation;
