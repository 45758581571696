import { useMutation } from '@apollo/client';
import { SEND_RATIFICATION_MAIL } from '@fingo/lib/graphql';

const useSendRatificationMail = (
  contactsIds,
  mailType,
  withCessionCertificate,
  invoiceIds,
  callback,
) => {
  const mailMutation = useMutation(SEND_RATIFICATION_MAIL, {
    variables: {
      contactsIds,
      mailType,
      withCessionCertificate,
      invoiceIds,
    },
    onCompleted: callback,
  });
  return mailMutation;
};

export default useSendRatificationMail;
