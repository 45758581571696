import { useBooleanState } from '@fingo/lib/hooks';
import React from 'react';

const useTourGuide = (Component) => {
  const [open, toggleOpen, setTrue, setFalse, setBoolean] = useBooleanState(false);
  const ConciliationComponent = (
    <Component
      isOpen={open}
      toggleOpen={toggleOpen}
    />
  );
  return { open, toggleOpen, ConciliationComponent, setTrue, setFalse, setBoolean };
};

export default useTourGuide;
