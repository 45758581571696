import { useMutation } from '@apollo/client';
import { CREATE_RATIFICATION_ACTION } from '@fingo/lib/graphql';

const useCreateRatificationManualManagement = (
  {
    invoices,
    ratificationContactsIds,
    ratificationActionType,
    ratificationComment,
    ratificationChannel,
    responded,
  },
  callback,
) => {
  const createAction = useMutation(CREATE_RATIFICATION_ACTION, {
    variables: {
      ratificationManagerIds: invoices.map((invoice) => invoice.ratificationmanager.id),
      ratificationContactsIds,
      ratificationActionType,
      ratificationComment,
      ratificationChannel,
      responded,
    },
    onCompleted: (data) => {
      callback(data);
    },
  });
  return createAction;
};

export default useCreateRatificationManualManagement;
